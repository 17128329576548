<template>
  <section id="container" class="efs_cont">
    <div class="title_wrap">
      <h1>마이페이지</h1>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <!-- <button class="mid_btn fr"> 수정 </button> -->
        <h2>기본 정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>회사명</label>
            <ul>
              <li> {{ userInfo.cmpyNm }}</li>
            </ul>
          </dd>
          <dd>
            <label>성명</label>
            <ul>
              <li> {{ userInfo.userNm }}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>휴대폰번호</label>
            <ul>
              <li>
                <kendo-maskedtextbox  ref="spTelNoRef"
                                      title="phone number"
                                      name="spTelNo"
                                      v-model.trim="spTelNo"
                                      mask="999-0000-0000"
                                      style="width: 70%;"
                                      placeholder="000-0000-0000"
                                      validationMessage="휴대전화번호를 입력해 주세요">
                </kendo-maskedtextbox>
              </li>
            </ul>
          </dd>
          <dd>
            <label>사용자 ID</label>
            <ul>
              <li>{{ userInfo.userId }}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>비밀번호</label>
            <ul>
              <li>
                <input
                    ref="userPwdRef"
                    type="password"
                    class="k-textbox"
                    :style="'width: 100%;'"
                    :placeholder="'비밀번호를 입력하세요.'"
                    v-model.trim="userPwd"/>
                <div v-show="pwdValidator" class="msg_red">비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.</div>
              </li>
            </ul>
          </dd>
          <dd>
            <label>비밀번호 재입력</label>
            <ul>
              <li>
                <input
                    ref="userPwdConfirmRef"
                    type="password"
                    class="k-textbox"
                    :style="'width: 100%;'"
                    :placeholder="'비밀번호를 재입력하세요.'"
                    v-model.trim="userPwdConfirm"/>
                <div v-show="userPwdConfirm ? userPwd !== userPwdConfirm : false" class="msg_red">새 비밀번호와 맞지 않습니다. 기재한 비밀번호를 확인해주세요.</div>
              </li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <!-- <button class="mid_btn fr"> 수정 </button> -->
        <h2>개인 권한 정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>관리자 여부</label>
            <ul>
              <li>일반/관리자</li>
            </ul>
          </dd>

        </li>
        <li>
          <dd>
            <label>이용약관 및 <br>개인정보 활용 동의 일시</label>
            <ul>
              <li>{{ userInfo.psiAgrDt ? 'Y' : 'N' }} <span class="mgl10">{{ userInfo.psiAgrDt }}</span>
              </li>
            </ul>
          </dd>
          <dd>
            <label>마케팅 동의 일시</label>
            <ul>
              <li>
                <kendo-switch ref="mktAgrRef"
                              v-model="mktAgrDt">
                </kendo-switch>
                <span class="mgl10">{{ userInfo.mktAgrDt }}</span>
              </li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button @click="$router.go(-1)" class="large_btn">취소</button>
        </li>
        <li class="w30">
          <button @click="onClickSave" class="large_btn orange">저장</button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'


export default {
  name: 'MyPageSave',
  computed : {
    pwdValidator : function (){
      if(!this.userPwd) return false
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/.test(this.userPwd)
      return !matched
    },
  },
  created () {
    const vm = this
    vm.getUserInfo()
  },
  methods : {
    getUserInfo : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/user/info`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.userInfo = response.data.resultData
              vm.spTelNo = vm.userInfo.spTelNo
              vm.mktAgrDt = !!vm.userInfo.mktAgrDt
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickSave : function() {
      const vm = this
      if(vm.validator()){
        kendo.confirm(`저장 하시겠습니까?`).done(function () {
          vm.saveUserInfo()
        })
      }
    },
    validator : function (){
      const vm = this

      if(EtnersCommonUtil.isNotEmpty(vm.userPwd)){
        if(vm.pwdValidator){
          kendo.alert("비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.").bind('close',function (){
            vm.$refs.userPwdRef.focus()
          })
          return false
        }
        if(EtnersCommonUtil.isEmpty(vm.userPwdConfirm)){
          kendo.alert("비밀번호를 재입력하세요.").bind('close',function (){
            vm.$refs.userPwdConfirmRef.focus()
          })
          return false
        }
      }

      if(EtnersCommonUtil.isNotEmpty(vm.userPwdConfirm)){

        if(EtnersCommonUtil.isEmpty(vm.userPwd)){
          kendo.alert("비밀번호를 입력해주세요.").bind('close',function (){
            vm.$refs.userPwdRef.focus()
          })
          return false
        }
        if(vm.userPwd !== vm.userPwdConfirm){
          kendo.alert("새 비밀번호와 맞지 않습니다. 기재한 비밀번호를 확인해주세요.").bind('close',function (){
            vm.$refs.userPwdConfirmRef.focus()
          })
          return false
        }
      }

      return true
    },
    saveUserInfo : function (){
      const vm = this
      let param = {
        userPwd : vm.userPwd,
        spTelNo : vm.spTelNo,
        mktAgrDt : vm.mktAgrDt
      }
      ApiUtil.put(`${ApiConfig.efsDomain}/efs/user/info`, param)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert("저장되었습니다.").bind('close', function() {
                vm.$router.push({ name : 'MyPageDetail'})
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }

  },
  data () {
    return {
      userInfo : {},
      spTelNo : '',
      userPwd : null,
      userPwdConfirm : null,
      mktAgrDt : null,
    }
  },
}
</script>

<style scoped>

</style>
